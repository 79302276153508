export interface Video {
    id: string;
    title: string;
    // length: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}

export const VIDEOS: Video[] = [
    {
        "id": "1iCGZBqIcJI",
        "title": "10 hours of silence occasionally broken up by Minecraft Cave Sounds",
        "hours": 10,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "TAeNlpUIlRs",
        "title": "5 Hours of Silence Being Randomly Broken by a Metal Pipe",
        "hours": 5,
        "minutes": 0,
        "seconds": 4
    },
    {
        "id": "Of9yvKINITg",
        "title": "10 hours of silence occasionally broken up by the Taco bell Bong",
        "hours": 10,
        "minutes": 0,
        "seconds": 1
    },
    {
        "id": "xTfWF9S8BCY",
        "title": "1 Hour of Silence Occasionally Broken up by a Dodgeball Sound Effect",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "bLvqxwhZvQ8",
        "title": "Ten hours of silence occasionally broken by smoke alarm chirp",
        "hours": 10,
        "minutes": 0,
        "seconds": 5
    },
    {
        "id": "QGroZXx2eGM",
        "title": "1 hour of silence occasionally broken up by sitcom laugh tracks",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "q0H6ujtM5gw",
        "title": "10 hours of silence occasionally interrupted by the bad to the bone riff",
        "hours": 10,
        "minutes": 0,
        "seconds": 36
    },
    {
        "id": "ukpO3-vzg-0",
        "title": "1 hour of silence occasionally broken up by \"waltuh\"",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "MahfSwXkD4s",
        "title": "1 hour of silence occasionally broken up by angry birds",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "P5RvvcaQEIs",
        "title": "1 hour of silence occasionally broken up by random windows sounds",
        "hours": 1,
        "minutes": 0,
        "seconds": 1
    },
    {
        "id": "y3utR7pge-A",
        "title": "1 hour of silence occasionally broken by the first 4 notes of Megalovania",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "zPAEXW54AP4",
        "title": "1 hour of Silence randomly interrupted by the Josh Hutcherson 2014 whistle edit",
        "hours": 1,
        "minutes": 0,
        "seconds": 0
    },
    {
        "id": "M9K2guaz_7E",
        "title": "1hr of silence but yoshi occasionally licks you",
        "hours": 0,
        "minutes": 59,
        "seconds": 34
    }
];