import { useState } from "preact/hooks";
import { Button } from "../components/Button.tsx";
import { YouTubeEmbed } from "./YouTubeEmbed.tsx";
import { VIDEOS, Video } from "../data/videoData.ts";

function calculateTotalSeconds(video: Video): number {
  return (video.hours || 0) * 3600 + (video.minutes || 0) * 60 + (video.seconds || 0);
}

export default function Interrupter() {
  const [embeds, setEmbeds] = useState<{ videoId: string; startTime: number }[]>([]);
  const [availableVideos, setAvailableVideos] = useState<Video[]>([...VIDEOS]);

  const addEmbed = () => {
    if (availableVideos.length === 0) {
      setAvailableVideos([...VIDEOS]);
    }

    const randomIndex = Math.floor(Math.random() * availableVideos.length);
    const selectedVideo = availableVideos[randomIndex];
    const totalSeconds = calculateTotalSeconds(selectedVideo);
    const randomStartTime = Math.floor(Math.random() * (totalSeconds - 10));

    setEmbeds([...embeds, { videoId: selectedVideo.id, startTime: randomStartTime }]);
    setAvailableVideos(availableVideos.filter((_, index) => index !== randomIndex));
  };

  const removeEmbed = (index: number) => {
    setEmbeds(embeds.filter((_, i) => i !== index));
  };

  const killAllEmbeds = () => {
    setEmbeds([]);
    setAvailableVideos([...VIDEOS]);
  };

  return (
    <div class="flex flex-col items-center gap-4">
      <div class="flex gap-4">
        <Button onClick={addEmbed}>Add Interruption</Button>
        <Button onClick={killAllEmbeds}>Kill All Interruptions</Button>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {embeds.map((embed, index) => (
          <YouTubeEmbed 
            key={index} 
            videoId={embed.videoId} 
            startTime={embed.startTime} 
            onClose={() => removeEmbed(index)}
          />
        ))}
      </div>
    </div>
  );
}